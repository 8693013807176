import React from 'react'
import SubHero from '../components/SubHero'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const Contact = () => (
  <Layout>
    <Helmet
      title="Project Discovery | NEXTGEN Code Company"
      meta={[{ name: 'description', content: 'Software development agency.' }]}
    />
    <SubHero
      defaultBackURL={'/services'}
      defaultBackText={'Services'}
      title="Project Discovery"
      subtitle=""
    />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <form
              className="project-discovery-form"
              name="project_discovery"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input
                type="hidden"
                name="form-name"
                value="project_discovery"
                required
              />

              <div className="field">
                <label className="label">Your Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="name"
                    placeholder="Full name"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Business name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="business_name"
                    placeholder="Business name"
                    required
                  />
                </div>
              </div>

              <div className="field checkboxes">
                <label className="label">
                  What type of services are you interested in?
                </label>
                <label className="checkbox">
                  <input name="service" value="Support Team" type="checkbox" />
                  Support Team{' '}
                  <a class="ng-learn-more-link" href="/services#services">
                    | Learn More
                  </a>
                </label>
                <label className="checkbox">
                  <input
                    name="service"
                    value="Consulting Team"
                    type="checkbox"
                  />
                  Consulting Team{' '}
                  <a class="ng-learn-more-link" href="/services#services">
                    | Learn More
                  </a>
                </label>
                <label className="checkbox">
                  <input name="service" value="Build Team" type="checkbox" />
                  Build Team{' '}
                  <a class="ng-learn-more-link" href="/services#services">
                    | Learn More
                  </a>
                </label>
                <label className="checkbox">
                  <input name="service" value="Velocity Team" type="checkbox" />
                  Velocity Team{' '}
                  <a class="ng-learn-more-link" href="/services#services">
                    | Learn More
                  </a>
                </label>
                <label className="checkbox">
                  <input
                    name="service"
                    value="Individual Project (Other)"
                    type="checkbox"
                  />
                  Individual Project (Other)
                </label>
              </div>

              <div className="field">
                <label className="label">Describe your goals</label>
                <div className="control">
                  <textarea
                    rows="3"
                    className="input textarea-input"
                    type="text"
                    name="goals"
                    placeholder="Goals"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Phone number</label>
                <div className="control">
                  <input
                    className="input"
                    type="phone"
                    name="phone_number"
                    placeholder="Phone #"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Best way to contact you?</label>
                <div className="control">
                  <label className="radio">
                    <input
                      type="radio"
                      name="Best way to contact"
                      value="phone"
                    />
                    Phone
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="Best way to contact"
                      value="email"
                    />
                    Email
                  </label>
                </div>
              </div>

              <button className="btn btn--dark" type="submit">
                Submit
              </button>
              <p>
                <br />
                <i>We will reach out ASAP</i>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Contact
